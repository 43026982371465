import React, { useEffect, useState, useRef, useCallback } from "react";
import { createRoot } from "react-dom/client";
import usePartySocket from "partysocket/react";
import type { SupUser } from "../party/server";
import type PartySocket from "partysocket";

type Cursor = { x: number; y: number };

declare global {
  interface Window {
    setupSup: (
      socket: PartySocket,
      user: SupUser,
      users: SupUser[],
      cursors: Record<string, Cursor>,
      kvStore: Record<string, any>
    ) => void;
    sup: any;
  }
}

const DynamicHTML: React.FC<{
  html: string;
  socket: PartySocket;
  user: SupUser;
  users: SupUser[];
  cursors: Record<string, Cursor>;
  kvStore: Record<string, any>;
}> = ({ html, socket, user, users, cursors, kvStore }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentHtml, setContentHtml] = useState("");
  const scriptRef = useRef<string | null>(null);
  const initializedRef = useRef(false);

  const injectAPI = useCallback(() => {
    if (!window.setupSup) {
      const apiScript = `
        window.setupSup = function(socket, user, users, cursors, kvStore) {
          window.sup = {
            user: user,
            users: users,
            cursors: cursors,
            kvStore: kvStore,
            subscribeToUsers: function(callback) {
              window.addEventListener('usersUpdated', (e) => callback(e.detail));
            },
            subscribeToCursors: function(callback) {
              window.addEventListener('cursorsUpdated', (e) => callback(e.detail));
            },
            subscribeToKV: function(key, callback) {
              window.addEventListener('kvUpdated', (e) => {
                if (e.detail.key === key) callback(e.detail.value);
              });
            },
            set: function(key, value) {
              socket.send(JSON.stringify({ type: 'set', key, value }));
            },
            get: function(key) {
              return this.kvStore[key];
            }
          };
        };
      `;
      const scriptElement = document.createElement("script");
      scriptElement.textContent = apiScript;
      document.head.appendChild(scriptElement);
    }
  }, []);

  useEffect(() => {
    injectAPI();
  }, [injectAPI]);

  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const scriptElement = tempDiv.querySelector("script");
    if (scriptElement) {
      scriptRef.current = scriptElement.innerHTML;
      scriptElement.remove();
    }
    setContentHtml(tempDiv.innerHTML);
  }, [html]);

  useEffect(() => {
    if (containerRef.current && contentHtml && !initializedRef.current) {
      containerRef.current.innerHTML = contentHtml;
      window.setupSup(socket, user, users, cursors, kvStore);

      if (scriptRef.current) {
        const newScript = document.createElement("script");
        newScript.textContent = scriptRef.current;
        document.body.appendChild(newScript);
      }

      initializedRef.current = true;
    }
  }, [contentHtml, socket, user, users, cursors, kvStore]);

  useEffect(() => {
    if (initializedRef.current) {
      window.sup.user = user;
      window.sup.users = users;
      window.sup.cursors = cursors;
      window.sup.kvStore = kvStore;

      if (containerRef.current) {
        const userInfoElement = containerRef.current.querySelector("#userInfo");
        if (userInfoElement) {
          userInfoElement.textContent = `Welcome, ${user.username}!`;
        }

        const counterElement = containerRef.current.querySelector("#counter");
        if (counterElement) {
          counterElement.textContent = `Counter: ${kvStore.counter || 0}`;
        }
      }
    }
  }, [user, users, cursors, kvStore]);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("usersUpdated", { detail: users }));
  }, [users]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent("cursorsUpdated", { detail: cursors })
    );
  }, [cursors]);

  useEffect(() => {
    Object.entries(kvStore).forEach(([key, value]) => {
      window.dispatchEvent(
        new CustomEvent("kvUpdated", { detail: { key, value } })
      );
    });
  }, [kvStore]);

  return (
    <div
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: contentHtml }}
      style={{ position: "absolute", inset: 0 }}
    />
  );
};

function App() {
  const [html, setHtml] = useState<string>("");
  const [users, setUsers] = useState<SupUser[]>([]);
  const [cursors, setCursors] = useState<Record<string, Cursor>>({});
  const [kvStore, setKVStore] = useState<Record<string, any>>({});
  const queryParams = new URLSearchParams(window.location.search);
  const userJson = queryParams.get("user");
  let user: SupUser;
  try {
    user = JSON.parse(userJson!);
  } catch {
    throw new Error("Invalid user query parameter");
  }
  const partyId = queryParams.get("partyId");
  if (!partyId) throw new Error("Missing partyId query parameter");
  const socket = usePartySocket({
    room: partyId,
    query: { user: JSON.stringify(user) },
    onMessage: (event: MessageEvent<string>) => {
      console.log("Received message:", event.data);
      const payload = JSON.parse(event.data) as
        | {
            type: "initialize";
            html: string;
            cursors: Record<string, Cursor>;
            users: SupUser[];
            kvStore: Record<string, any>;
          }
        | { type: "cursor-update"; userId: string; position: Cursor }
        | { type: "user-join"; user: SupUser }
        | { type: "user-leave"; userId: string }
        | { type: "kv-update"; key: string; value: any };

      switch (payload.type) {
        case "initialize":
          setHtml(payload.html);
          setCursors(payload.cursors);
          setUsers(payload.users);
          setKVStore(payload.kvStore);
          break;
        case "cursor-update":
          setCursors((cursors) => ({
            ...cursors,
            [payload.userId]: payload.position,
          }));
          break;
        case "user-join":
          setUsers((users) => {
            if (users.find((user) => user.id === payload.user.id)) return users;
            return [...users, payload.user];
          });
          break;
        case "user-leave":
          setUsers((users) =>
            users.filter((user) => user.id !== payload.userId)
          );
          break;
        case "kv-update":
          setKVStore((kvStore) => ({
            ...kvStore,
            [payload.key]: payload.value,
          }));
          break;
      }
    },
  });

  const onMouseMove = (event: React.MouseEvent) => {
    socket.send(
      JSON.stringify({ type: "cursor", x: event.clientX, y: event.clientY })
    );
  };

  return (
    <main style={{ position: "fixed", inset: 0 }} onMouseMove={onMouseMove}>
      <DynamicHTML
        html={html}
        socket={socket}
        user={user}
        users={users}
        cursors={cursors}
        kvStore={kvStore}
      />
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
